import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import { extractPermissions } from '@/utils/extractPermissions'

export const usePermissionsStore = defineStore('permissions', () => {
  /**
   * @type {string[]}
   */
  const permissions = ref([])

  function setPermissions(user) {
    permissions.value = extractPermissions(user)
  }

  const canBuyEsim = computed(() => {
    return permissions.value.includes('buy-esims')
  })

  const canBuyTopup = computed(() => {
    return permissions.value.includes('buy-topups')
  })

  const canListManualOrders = computed(() => {
    return permissions.value.includes(
      'list-Modules\\Package\\Models\\Hub\\Order'
    )
  })

  const canViewManualOrder = computed(() => {
    return permissions.value.includes(
      'show-Modules\\Package\\Models\\Hub\\Order'
    )
  })

  const canListApiOrders = computed(() => {
    return permissions.value.includes('list-Modules\\Package\\Models\\Order')
  })

  const canViewApiOrders = computed(() => {
    return permissions.value.includes('show-Modules\\Package\\Models\\Order')
  })

  const canListSims = computed(() => {
    return permissions.value.includes('list-Modules\\Sim\\Models\\Sim')
  })

  const canAssignUser = computed(() => {
    return permissions.value.includes('assign-create-Modules\\Sim\\Models\\Sim')
  })

  const canUpdateUser = computed(() => {
    return permissions.value.includes('assign-update-Modules\\Sim\\Models\\Sim')
  })

  const canShareEsim = computed(() => {
    return permissions.value.includes('share-Modules\\Sim\\Models\\Sim')
  })

  const canViewUsage = computed(() => {
    return permissions.value.includes('usage-Modules\\Sim\\Models\\Sim')
  })

  const canViewAPIIntegration = computed(() => {
    return permissions.value.includes('show-api-credentials')
  })

  const canViewCredits = computed(() => {
    return permissions.value.includes(
      'list-Modules\\Finance\\Models\\AccountTransaction'
    )
  })

  const canViewBalanceSummary = computed(() => {
    return permissions.value.includes('pp.finance.airalo-credits-summary.show')
  })

  const canViewDashboard = computed(() => {
    return permissions.value.includes('insights-dashboard')
  })

  const getAllPermissions = computed(() => {
    return permissions.value
  })

  const canViewAddCredit = computed(() => {
    return permissions.value.includes('pp.finance.airalo-credits.add')
  })

  const canViewUser = computed(() => {
    return permissions.value.includes('list-Modules\\User\\Models\\User')
  })

  const canViewAddDomain = computed(() => {
    return permissions.value.includes('pp.company.domains.create')
  })

  const canViewVerifyDomain = computed(() => {
    return permissions.value.includes('pp.company.domains.verify')
  })

  const canViewBilling = computed(() => {
    return permissions.value.includes('pp.finance.invoice.list')
  })

  const canShowUser = computed(() => {
    return permissions.value.includes('show-Modules\\User\\Models\\User')
  })

  const canAddUser = computed(() => {
    return permissions.value.includes('add-Modules\\User\\Models\\User')
  })

  const canEditUser = computed(() => {
    return permissions.value.includes('edit-Modules\\User\\Models\\User')
  })

  const canViewSSOSaml = computed(() => {
    return permissions.value.includes('pp.company.saml.show')
  })

  const canViewBrandSettingsList = computed(() => {
    return permissions.value.includes(
      'list-Modules\\Company\\Models\\CompanyBrand'
    )
  })

  const canViewBranchSettingsList = computed(() => {
    return permissions.value.includes('pp.companies.branches.list')
  })

  const canViewBranch = computed(() => {
    return permissions.value.includes('pp.companies.business_units.show')
  })

  const canAddBranch = computed(() => {
    return permissions.value.includes('pp.companies.business_units.add')
  })

  const canUpdateBranch = computed(() => {
    return permissions.value.includes('pp.companies.business_units.update')
  })

  const canDeleteBranch = computed(() => {
    return permissions.value.includes('pp.companies.business_units.delete')
  })

  const canViewBrandSettingsDetails = computed(() => {
    return permissions.value.includes(
      'show-Modules\\Company\\Models\\CompanyBrand'
    )
  })

  const canAddBrandSettings = computed(() => {
    return permissions.value.includes(
      'add-Modules\\Company\\Models\\CompanyBrand'
    )
  })

  const canEditBrandSettings = computed(() => {
    return permissions.value.includes(
      'edit-Modules\\Company\\Models\\CompanyBrand'
    )
  })

  const canViewCompanySettings = computed(() => {
    return canViewSSOSaml.value || canViewBrandSettingsList.value
  })

  const canViewSpendingSettings = computed(() => {
    return permissions.value.includes('pp.company.bill_to_organization.manage')
  })

  return {
    permissions,
    setPermissions,
    canBuyEsim,
    canBuyTopup,
    canListManualOrders,
    canViewManualOrder,
    canListApiOrders,
    canViewApiOrders,
    canListSims,
    canAssignUser,
    canUpdateUser,
    canShareEsim,
    canViewUsage,
    canViewAPIIntegration,
    canViewCredits,
    canViewBalanceSummary,
    canViewDashboard,
    getAllPermissions,
    canViewAddCredit,
    canViewUser,
    canShowUser,
    canAddUser,
    canEditUser,
    canViewBilling,
    canViewAddDomain,
    canViewVerifyDomain,
    canViewCompanySettings,
    canViewSSOSaml,
    canViewBrandSettingsList,
    canViewBrandSettingsDetails,
    canAddBrandSettings,
    canEditBrandSettings,
    canViewBranchSettingsList,
    canViewSpendingSettings,
    canViewBranch,
    canAddBranch,
    canUpdateBranch,
    canDeleteBranch,
  }
})
